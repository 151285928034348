import { ResponsiveLine } from '@nivo/line';
import { useEffect, useMemo, useState } from 'react';

const useWindowSize = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

export const Graph = () => {
  const width = useWindowSize();
  const isMobile = width < 768; // md breakpoint

  const dataSet = useMemo(
    () => [
      {
        id: isMobile ? 'Eoli.' : 'Eolico',
        color: '#8addcd',
        data: [
          { x: '2009', y: 135 },
          { x: '2011', y: 71 },
          { x: '2013', y: 70 },
          { x: '2015', y: 55 },
          { x: '2017', y: 45 },
          { x: '2019', y: 40 },
          { x: '2021', y: 38 },
          { x: '2023', y: 50 },
        ],
      },
      {
        id: isMobile ? 'Sola.' : 'Solare',
        color: '#f4e989',
        data: [
          { x: '2009', y: 359 },
          { x: '2011', y: 157 },
          { x: '2013', y: 104 },
          { x: '2015', y: 64 },
          { x: '2017', y: 50 },
          { x: '2019', y: 40 },
          { x: '2021', y: 36 },
          { x: '2023', y: 60 },
        ],
      },
      {
        id: 'Gas',
        color: '#efbe70',
        data: [
          { x: '2009', y: 83 },
          { x: '2011', y: 83 },
          { x: '2013', y: 74 },
          { x: '2015', y: 64 },
          { x: '2017', y: 60 },
          { x: '2019', y: 56 },
          { x: '2021', y: 60 },
          { x: '2023', y: 70 },
        ],
      },
      {
        id: isMobile ? 'Carb.' : 'Carbone',
        color: '#efd0b8',
        data: [
          { x: '2009', y: 111 },
          { x: '2011', y: 111 },
          { x: '2013', y: 105 },
          { x: '2015', y: 108 },
          { x: '2017', y: 102 },
          { x: '2019', y: 109 },
          { x: '2021', y: 108 },
          { x: '2023', y: 117 },
        ],
      },
      {
        id: isMobile ? 'Nucl.' : 'Nucleare',
        color: '#f99989',
        data: [
          { x: '2009', y: 123 },
          { x: '2011', y: 95 },
          { x: '2013', y: 104 },
          { x: '2015', y: 117 },
          { x: '2017', y: 148 },
          { x: '2019', y: 155 },
          { x: '2021', y: 167 },
          { x: '2023', y: 180 },
        ],
      },
    ],
    [],
  );

  const margin = isMobile
    ? { top: 50, right: 20, bottom: 120, left: 60 }
    : { top: 50, right: 110, bottom: 50, left: 60 };

  const legends = [
    Object.freeze({
      anchor: isMobile ? 'bottom' : 'bottom-right',
      direction: isMobile ? 'row' : 'column',
      justify: false,
      translateX: isMobile ? 0 : 100,
      translateY: isMobile ? 80 : 0,
      itemDirection: 'left-to-right',
      itemWidth: isMobile ? 50 : 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        Object.freeze({
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        }),
      ],
      itemsSpacing: isMobile ? 0 : 10,
      wrap: isMobile ? true : false,
    }),
  ];

  return (
    <ResponsiveLine
      data={dataSet}
      margin={margin}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isMobile ? -45 : 0,
        legend: 'Anno',
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '$/MWh',
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      enableTouchCrosshair={true}
      useMesh={true}
      legends={legends}
      tooltip={({ point }) => (
        <div className="bg-white p-2 shadow-lg rounded-lg border">
          <strong>{point.serieId}</strong>: {point.data.y.toString()} $/MWh
          <div className="text-sm text-gray-600">{point.data.x.toString()}</div>
        </div>
      )}
      colors={(d) => d.color}
      lineWidth={3}
    />
  );
};
