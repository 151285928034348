import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Checkbox } from '../ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const EnergyInformationForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const form = useMemberForm(member, onSuccess);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[620px]" onSubmit={form.onSubmit}>
          <ConsumerFormFields form={form} />
          {member.member_role !== 'CONSUMER' && <ProducerFormFields form={form} />}
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': form.isPending })}
              disabled={form.isPending}
            >
              {form.isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

const ProducerFormFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* power_plant_activation_date */}
      <Controller
        control={form.control}
        name="power_plant_activation_date"
        render={({ field }) => (
          <FormItem className="mb-6 mt-6 flex flex-col">
            <Label>
              {t('registration.energyInformationProducer.powerPlantActivationDate')}
            </Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'justify-start text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP', { locale: it }) // TODO: use i18n
                  ) : (
                    <span>{t('misc.selectDate')}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className=" w-auto p-0">
                <Calendar
                  locale={it}
                  mode="single"
                  captionLayout="dropdown-buttons"
                  selected={new Date(field.value ?? '')}
                  onSelect={(date) => {
                    if (!date) return;
                    const utcDate = new Date(
                      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                    );
                    form.setValue('power_plant_activation_date', utcDate.toISOString());
                  }}
                  fromYear={1900}
                  toYear={2030}
                  {...form.register('power_plant_activation_date', { required: true })}
                  required
                />
              </PopoverContent>
            </Popover>
            {form.formState.errors.power_plant_activation_date && (
              <FormMessage>
                {form.formState.errors.power_plant_activation_date.type === 'required' &&
                !form.formState.errors.power_plant_activation_date.ref?.value
                  ? t(
                      'registration.energyInformationProducer.powerPlantActivationDateValidation',
                    )
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      {/* system_power */}
      <Controller
        name="system_power"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.energyInformationProducer.systemPower')}</Label>
            <FormControl>
              <Input
                {...form.register('system_power', { required: true })}
                {...field}
                type="number"
                placeholder={t(
                  'registration.energyInformationProducer.systemPowerPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.system_power && (
              <FormMessage>
                {form.formState.errors.system_power.type === 'required'
                  ? t('registration.energyInformationProducer.systemPowerValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationProducer.systemPowerDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      {/* // power_plant_latitude */}
      <Controller
        name="power_plant_latitude"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>
              {t('registration.energyInformationProducer.powerPlantLatitude')}
            </Label>
            <FormControl>
              <Input
                {...form.register('power_plant_latitude', {
                  required: true,
                  pattern: {
                    value: /^(\d{2})\.(\d{4,8})$/,
                    message: t(
                      'registration.energyInformationProducer.powerPlantLatitudeValidation',
                    ),
                  },
                })}
                {...field}
                placeholder={t(
                  'registration.energyInformationProducer.powerPlantLatitudePlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.power_plant_latitude && (
              <FormMessage>
                {form.formState.errors.power_plant_latitude
                  ? t(
                      'registration.energyInformationProducer.powerPlantLatitudeValidation',
                    )
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationProducer.powerPlantLatitudeDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      {/* power_plant_longitude */}
      <Controller
        name="power_plant_longitude"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>
              {t('registration.energyInformationProducer.powerPlantLongitude')}
            </Label>
            <FormControl>
              <Input
                {...form.register('power_plant_longitude', {
                  required: true,
                  pattern: {
                    value: /^(\d{2})\.(\d{4,8})$/,
                    message: t(
                      'registration.energyInformationProducer.powerPlantLongitudeValidation',
                    ),
                  },
                })}
                {...field}
                placeholder={t(
                  'registration.energyInformationProducer.powerPlantLongitudePlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.power_plant_longitude && (
              <FormMessage>
                {form.formState.errors.power_plant_longitude
                  ? t(
                      'registration.energyInformationProducer.powerPlantLongitudeValidation',
                    )
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationProducer.powerPlantLongitudeDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      {/* code_censimp */}
      <Controller
        name="code_censimp"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.energyInformationProducer.codeCensimp')}</Label>
            <FormControl>
              <Input
                {...form.register('code_censimp', { required: true })}
                {...field}
                placeholder={t(
                  'registration.energyInformationProducer.codeCensimpPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.code_censimp && (
              <FormMessage>
                {form.formState.errors.code_censimp.type === 'required'
                  ? t('registration.energyInformationProducer.codeCensimpValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationProducer.codeCensimpDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      {/* code_terna */}
      <Controller
        name="code_terna"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.energyInformationProducer.codeTerna')}</Label>
            <FormControl>
              <Input
                {...form.register('code_terna', { required: true })}
                {...field}
                placeholder={t(
                  'registration.energyInformationProducer.codeTernaPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.code_terna && (
              <FormMessage>
                {form.formState.errors.code_terna.type === 'required'
                  ? t('registration.energyInformationProducer.codeTernaValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationProducer.codeTernaDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
    </>
  );
};

const ConsumerFormFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name="pod_id"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.energyInformationConsumer.podId')}</Label>
            <FormControl>
              <Input
                {...form.register('pod_id', {
                  required: true,
                  pattern: {
                    value: /^([Ii][Tt])(\d{3})([eE])(\d{8,9})$/,
                    message: t('registration.energyInformationConsumer.podIdValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.energyInformationConsumer.podIdPlaceholder')}
              />
            </FormControl>
            {form.formState.errors.pod_id && (
              <FormMessage>
                {form.formState.errors.pod_id
                  ? t('registration.energyInformationConsumer.podIdValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.energyInformationConsumer.podIdDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      {/* already_in_gse_platform */}
      <FormField
        name="already_in_gse_platform"
        control={form.control}
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  form.setValue('already_in_gse_platform', Boolean(checked));
                }}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                {t('registration.energyInformationConsumer.alreadyInGsePlatform')}
              </FormLabel>
              <FormDescription>
                {t(
                  'registration.energyInformationConsumer.alreadyInGsePlatformDescription',
                )}
              </FormDescription>
            </div>
          </FormItem>
        )}
      />
      {form.watch('already_in_gse_platform') && (
        <Controller
          name="vat_number"
          control={form.control}
          render={({ field }) => (
            <FormItem className="mb-6 mt-4">
              <Label>{t('registration.organizationDetails.vatNumber')}</Label>
              <FormControl>
                <Input
                  {...form.register('vat_number', {
                    required: true,
                    pattern: {
                      value:
                        /^$|^([Ii][Tt])?(\d{11})$|^(?![iI][tT])([a-zA-Z]{2,3})(\d+)$/,
                      message: t('registration.organizationDetails.vatNumberValidation'),
                    },
                  })}
                  {...field}
                  placeholder={t('registration.organizationDetails.vatNumberPlaceholder')}
                />
              </FormControl>
              {form.formState.errors.vat_number && (
                <FormMessage>
                  {form.formState.errors.vat_number
                    ? t('registration.organizationDetails.vatNumberValidation')
                    : null}
                </FormMessage>
              )}
              <FormDescription>
                {t('registration.energyInformationConsumer.vatNumberDescription')}
              </FormDescription>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
