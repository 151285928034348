import { Member } from '../types/member';

export const sanitizeMemberFormData = (member: Partial<Member>) => {
  return {
    email: member.email || undefined,
    pod_id: member.pod_id || undefined,
    type: member.type || undefined,
    member_role: member.member_role || undefined,
    first_name: member.first_name || undefined,
    last_name: member.last_name || undefined,
    business_report: member.business_report || undefined,
    address_country: member.address_country || undefined,
    address_city: member.address_city || undefined,
    address_municipality: member.address_municipality || undefined,
    address_region: member.address_region || undefined,
    address_route: member.address_route || undefined,
    address_street_number: member.address_street_number || undefined,
    address_zip_code: member.address_zip_code || undefined,
    ateco_code: member.ateco_code || undefined,
    company_fiscal_number: member.company_fiscal_number || undefined,
    is_associate_firm: member.is_associate_firm || false,
    already_in_gse_platform: member.already_in_gse_platform || false,
    is_isee: member.is_isee || false,
    subject_type: member.subject_type || undefined,
    phone_number: member.phone_number || undefined,
    energy_provider: member.energy_provider || undefined,
    power_supply: member.power_supply || undefined,
    family_members: member.family_members || 0,
    squared_meters: member.squared_meters || 0,
    energy_type: member.energy_type || undefined,
    system_power: member.system_power || undefined,
    fiscal_number: member.fiscal_number || undefined,
    vat_number: member.vat_number || undefined,
    birth_date: member.birth_date || undefined,
    legal_representative_name: member.legal_representative_name || undefined,
    company_name: member.company_name || undefined,
    power_plant_activation_date: member.power_plant_activation_date || undefined,
    birth_place_country: member.birth_place_country || undefined,
    birth_place_region: member.birth_place_region || undefined,
    birth_place_city: member.birth_place_city || undefined,
    birth_place_municipality: member.birth_place_municipality || undefined,
    power_plant_latitude: member.power_plant_latitude || undefined,
    power_plant_longitude: member.power_plant_longitude || undefined,
    code_censimp: member.code_censimp || undefined,
    code_terna: member.code_terna || undefined,
    cadastral_section: member.cadastral_section || undefined,
    cadastral_paper: member.cadastral_paper || undefined,
    cadastral_particle: member.cadastral_particle || undefined,
    cadastral_subsection: member.cadastral_subsection || undefined,
    are_contracts_signed: member.are_contracts_signed || undefined,
  };
};
