import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { Callout } from '@/components/ui/custom/callout';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader } from '@/components/ui/loader';
import { supabase } from '@/lib/supabase/config';

export const Login = () => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get('email');
  const isPasswordReset = true;
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: email ?? '',
      password: '',
    },
  });

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const response = await supabase.auth.signInWithPassword({ email, password });
      if (response.error && response.error.status === 400) {
        setError(t('login.invalidCredentials'));
      }
      navigate('/');
    } catch (err) {
      toast.error(t('login.error'), {
        description: JSON.stringify(err),
      });
    }
  };

  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-[840px] mx-auto lg:grid-cols-2 lg:px-0 overflow-auto">
      <div className="relative hidden bg-background h-full p-10 lg:flex items-center justify-center flex-col">
        <div>
          <img alt="logo" src="/energico.png" className="h-16" />
          <blockquote className="space-y-2">
            <p className="text-md">{t('login.punchline')}</p>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 max-w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">{t('login.title')}</h1>
            <p className="text-sm text-muted-foreground">{t('login.subtitle')}</p>
          </div>
          {!isPasswordReset && (
            <Callout
              text={
                <div>
                  <b>{t('login.calloutTitle')}</b>
                  <div className="my-2">{t('login.calloutDescription')}</div>
                  <div>{t('login.calloutStep1')}</div>
                  <div>{t('login.calloutStep2')}</div>
                  <b className="block mt-2">{t('login.calloutStep3')}</b>
                </div>
              }
              cta={
                <Button
                  onClick={() => {
                    navigate('/forgot-password');
                  }}
                >
                  {t('login.setPassword')}
                </Button>
              }
            />
          )}
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <Label className="sr-only" htmlFor="email">
                  {t('login.email')}
                </Label>
                <Input
                  id="email"
                  placeholder={t('login.emailPlaceholder')}
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('login.emailInvalid'),
                    },
                  })}
                />
                <Label className="sr-only" htmlFor="password">
                  {t('login.password')}
                </Label>
                <Input
                  id="password"
                  placeholder={t('login.passwordPlaceholder')}
                  type="password"
                  autoCapitalize="none"
                  autoComplete="current-password"
                  autoCorrect="off"
                  {...register('password', {
                    required: true,
                  })}
                />
              </div>
              {error !== '' && <Callout variant="danger" text={error} />}
              <Button disabled={isSubmitting}>
                {isSubmitting && <Loader className="mr-1" />}
                {t('login.cta')}
              </Button>
            </div>
          </form>
          <p className="px-8 text-center text-sm text-muted-foreground">
            <Trans i18nKey="login.termsAndPrivacy">
              By clicking continue, you agree to our
              <a
                href="https://www.energico.io/terms-and-conditions-app"
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>
              .
            </Trans>
          </p>
          {/* <p className="text-center text-sm text-muted-foreground">
            {t('login.noAccount')}{' '}
            <Link
              to="/signup"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t('login.register')}
            </Link>
          </p> */}
          <p className="text-center text-sm text-muted-foreground">
            <Link
              to="/forgot-password"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t('login.forgotPassword')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
