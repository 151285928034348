import { useEffect } from 'react';

import { useAuth } from '@/components/auth/AuthProvider';
import { fetchWithSupabase } from '@/lib/api/fetch';
import { UpdateMemberProfileResponse } from '@/lib/api/types';
import { UPDATE_MEMBER_URL } from '@/lib/api/url';

import { useUserCommunity } from '../../community/hooks/useUserCommunity';

export const useUpdateMemberContracts = (isMemberFinishedRegistration: boolean) => {
  const { member } = useUserCommunity();
  console.log('isMemberFinishedRegistration', isMemberFinishedRegistration);
  const { session } = useAuth();

  useEffect(() => {
    if (!member) return;
    const updateContractsSigned = async () => {
      if (!session) {
        throw new Error('User not authenticated');
      }
      let data: { are_contracts_signed: boolean };
      if (isMemberFinishedRegistration && !member.has_received_contract) {
        data = { are_contracts_signed: true };
      } else if (!isMemberFinishedRegistration && member.has_received_contract) {
        data = { are_contracts_signed: false };
      } else {
        return;
      }

      try {
        console.log('calling member update');
        const response = await fetchWithSupabase<UpdateMemberProfileResponse>(
          UPDATE_MEMBER_URL(session.user.id),
          {
            method: 'POST',
            body: JSON.stringify(data),
          },
        );
        if (!response.success) {
          throw new Error('API request failed');
        }
        console.log(response.member);
      } catch (error) {
        console.error('Error updating contracts signed:', error);
      }
    };

    updateContractsSigned();
  }, [isMemberFinishedRegistration]);
};
