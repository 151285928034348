import { useUserCommunity } from '@/components/community/hooks/useUserCommunity';
import { Member } from '@/components/community/types/member';
import { useUserContracts } from '@/components/documents/hooks/useUserContracts';

import { Step } from '../types/step';

export const useIsMemberFinishedRegistration = () => {
  const { member } = useUserCommunity();
  const { hasUnsignedContracts } = useUserContracts();

  if (!member) {
    return {
      isMemberFinishedRegistration: false,
      currentStep: undefined,
    };
  }

  return {
    isMemberFinishedRegistration: isMemberFinishedRegistration(
      member,
      hasUnsignedContracts,
    ),
    currentStep: getCurrentStep(member, hasUnsignedContracts),
    isMemberTypeStepCompleted: isMemberTypeStepCompleted(member),
    isMemberRoleStepCompleted: isMemberRoleStepCompleted(member),
    isMemberDetailsStepCompleted: isMemberDetailsStepCompleted(member),
    isEnergyInformationStepCompleted: isEnergyInformationStepCompleted(member),
    isLocationInformationStepCompleted: isLocationInformationStepCompleted(member),
    isFinancialInformationStepCompleted: isFinancialInformationStepCompleted(member),
    isMemberSignedTheContract: !hasUnsignedContracts,
  };
};

export const currentStepToPath = (currentStep: Step | undefined) => {
  switch (currentStep) {
    case 'type':
    case undefined:
      return 'register/select-member-type';
    case 'role':
      return 'register/choose-your-role';
    case 'details':
      return 'register/member-details';
    case 'energy':
      return 'register/energy-information';
    case 'location':
      return 'register/location-information';
    case 'contract':
      return 'register/review-sign-contract';
  }
};

const getCurrentStep = (
  member: Member,
  hasUnsignedContracts: boolean,
): Step | undefined => {
  if (!isMemberTypeStepCompleted(member)) {
    return 'type';
  }

  if (!isMemberRoleStepCompleted(member)) {
    return 'role';
  }

  if (!isMemberDetailsStepCompleted(member)) {
    return 'details';
  }

  if (!isEnergyInformationStepCompleted(member)) {
    return 'energy';
  }

  if (!isLocationInformationStepCompleted(member)) {
    return 'location';
  }

  if (hasUnsignedContracts) {
    return 'contract';
  }
};

const isMemberTypeStepCompleted = (member: Member) => {
  return Boolean(member.type);
};

const isMemberRoleStepCompleted = (member: Member) => {
  return Boolean(member.member_role);
};

const isMemberDetailsStepCompleted = (member: Member) => {
  if (member.type === 'PRIVATE_COMPANY') {
    return Boolean(
      member.company_name &&
        member.company_fiscal_number &&
        member.vat_number &&
        member.phone_number &&
        member.ateco_code &&
        member.legal_representative_name &&
        member.fiscal_number &&
        member.birth_date &&
        member.birth_place_country &&
        member.birth_place_region &&
        member.birth_place_city &&
        member.birth_place_municipality,
    );
  }

  return Boolean(
    member.first_name &&
      member.last_name &&
      member.phone_number &&
      member.fiscal_number &&
      member.birth_date &&
      member.birth_place_country &&
      member.birth_place_region &&
      member.birth_place_city &&
      member.birth_place_municipality,
  );
};

const isEnergyInformationStepCompleted = (member: Member) => {
  if (member.member_role === 'CONSUMER') {
    return Boolean(member.pod_id && member.already_in_gse_platform !== undefined);
  }
  return Boolean(
    member.pod_id &&
      member.already_in_gse_platform !== undefined &&
      member.power_plant_activation_date &&
      member.power_plant_latitude &&
      member.power_plant_longitude &&
      member.code_censimp &&
      member.code_terna,
  );
};

const isLocationInformationStepCompleted = (member: Member) => {
  return Boolean(
    member.address_country &&
      member.address_region &&
      member.address_city &&
      member.address_municipality &&
      member.address_zip_code &&
      member.address_route &&
      member.address_street_number &&
      member.cadastral_section &&
      member.cadastral_paper &&
      member.cadastral_particle &&
      member.cadastral_subsection,
  );
};

const isFinancialInformationStepCompleted = (member: Member) => {
  return Boolean(member.is_isee !== undefined);
};

const isMemberFinishedRegistration = (member: Member, hasUnsignedContracts: boolean) => {
  return (
    isMemberTypeStepCompleted(member) &&
    isMemberRoleStepCompleted(member) &&
    isMemberDetailsStepCompleted(member) &&
    isEnergyInformationStepCompleted(member) &&
    isLocationInformationStepCompleted(member) &&
    !hasUnsignedContracts
  );
};

export const isMemberStartedRegistration = (member: Member) => {
  return (
    isMemberTypeStepCompleted(member) ||
    isMemberRoleStepCompleted(member) ||
    isMemberDetailsStepCompleted(member) ||
    isEnergyInformationStepCompleted(member) ||
    isLocationInformationStepCompleted(member)
  );
};

export const isMemberReadyForContractSigning = (member: Member) => {
  return (
    isMemberTypeStepCompleted(member) &&
    isMemberRoleStepCompleted(member) &&
    isMemberDetailsStepCompleted(member) &&
    isEnergyInformationStepCompleted(member) &&
    isLocationInformationStepCompleted(member)
  );
};
