import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { useAuth } from '../auth/AuthProvider';
import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Checkbox } from '../ui/checkbox';
import { AddressAutocomplete } from '../ui/custom/addressAutocomplete';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const MemberDetailsForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const form = useMemberForm(member, onSuccess);
  const { session } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (session?.user.email) {
      form.setValue('email', session.user.email); // Ensure email is registered in the form
    }
  }, [session?.user.email, form]);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={form.onSubmit}>
          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>{t('community.members.form.email')}</Label>
                <FormControl>
                  <Input {...field} value={session?.user.email} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {member.type === 'PRIVATE_COMPANY' ? (
            <PrivateCompanyFields form={form} />
          ) : (
            // Default to legal person
            <LegalPersonFields form={form} />
          )}
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': form.isPending })}
              disabled={form.isPending}
            >
              {form.isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

const PrivateCompanyFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name="company_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.companyName')}</Label>
            <FormControl>
              <Input
                {...form.register('company_name', { required: true })}
                {...field}
                placeholder={t('registration.organizationDetails.companyNamePlaceholder')}
              />
            </FormControl>
            {form.formState.errors.company_name && (
              <FormMessage>
                {form.formState.errors.company_name.type === 'required'
                  ? t('registration.organizationDetails.companyNameValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.organizationDetails.companyNameDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <Controller
        name="company_fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.companyFiscalNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('company_fiscal_number', { required: true })}
                {...field}
                placeholder={t(
                  'registration.organizationDetails.companyFiscalNumberPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.company_fiscal_number && (
              <FormMessage>
                {form.formState.errors.company_fiscal_number.type === 'required'
                  ? t('registration.organizationDetails.companyFiscalNumberValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.organizationDetails.companyFiscalNumberDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <Controller
        name="vat_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.vatNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('vat_number', {
                  required: true,
                  pattern: {
                    value: /^$|^([Ii][Tt])?(\d{11})$|^(?![iI][tT])([a-zA-Z]{2,3})(\d+)$/,
                    message: t('registration.organizationDetails.vatNumberValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.organizationDetails.vatNumberPlaceholder')}
              />
            </FormControl>
            {form.formState.errors.vat_number && (
              <FormMessage>
                {form.formState.errors.vat_number
                  ? t('registration.organizationDetails.vatNumberValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.organizationDetails.vatNumberDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <Controller
        name="phone_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.phoneNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('phone_number', {
                  required: true,
                  pattern: {
                    value: /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,8}$/,
                    message: t('registration.memberDetails.phoneNumberValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.memberDetails.phoneNumberPlaceholder')}
              />
            </FormControl>
            {form.formState.errors.phone_number && (
              <FormMessage>
                {form.formState.errors.phone_number
                  ? t('registration.memberDetails.phoneNumberValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="ateco_code"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.atecoCode')}</Label>
            <FormControl>
              <Input
                {...form.register('ateco_code', {
                  required: true,
                  pattern: {
                    value:
                      /^([0-9]{1,2})((?:[.]?([0-9]{1,2}))?$|((?:[.]?([0-9]{2}))?[.]?([0-9]{1,2})))?$/,
                    message: t('registration.organizationDetails.atecoCodeValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.organizationDetails.atecoCodePlaceholder')}
              />
            </FormControl>
            {form.formState.errors.ateco_code && (
              <FormMessage>
                {form.formState.errors.ateco_code
                  ? t('registration.organizationDetails.atecoCodeValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.organizationDetails.atecoCodeDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <Controller
        name="business_report"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.businessReport')}</Label>
            <FormControl>
              <Input
                {...form.register('business_report', { required: true })}
                {...field}
                placeholder={t(
                  'registration.organizationDetails.businessReportPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.business_report && (
              <FormMessage>
                {form.formState.errors.business_report.type === 'required'
                  ? t('registration.organizationDetails.businessReportValidation')
                  : null}
              </FormMessage>
            )}
            <FormDescription>
              {t('registration.organizationDetails.businessReportDescription')}
            </FormDescription>
          </FormItem>
        )}
      />
      <FormField
        name="is_associate_firm"
        control={form.control}
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(checked) => {
                  form.setValue('is_associate_firm', Boolean(checked));
                }}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                {t('registration.organizationDetails.isAssociateFirm')}
              </FormLabel>
            </div>
          </FormItem>
        )}
      />
      <Controller
        name="subject_type"
        control={form.control}
        render={({ field }) => (
          <FormItem className="space-y-3 mt-4">
            <FormLabel>{t('registration.organizationDetails.subjectType')}</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex flex-col space-y-1"
                required
              >
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="LOCAL_ENTITY" />
                  </FormControl>
                  {/* "LOCAL_ENTITY" | "SME" | "PRIVATE_LAW_ASSOCIATION" | "OTHER" */}
                  <FormLabel className="font-normal">
                    {t(
                      'registration.organizationDetails.subjectTypeOptions.subjectTypeLocalEntity',
                    )}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="SME" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t('registration.organizationDetails.subjectTypeOptions.sme')}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="PRIVATE_LAW_ASSOCIATION" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t(
                      'registration.organizationDetails.subjectTypeOptions.privateLawAssociation',
                    )}
                  </FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value="OTHER" />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {t('registration.organizationDetails.subjectTypeOptions.other')}
                  </FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
            {form.formState.errors.subject_type && (
              <FormMessage>
                {form.formState.errors.subject_type.type === 'required'
                  ? t('registration.organizationDetails.subjectTypeValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="legal_representative_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6 mt-4">
            <Label>{t('registration.organizationDetails.legalRepresentativeName')}</Label>
            <FormControl>
              <Input
                {...form.register('legal_representative_name', { required: true })}
                {...field}
                placeholder={t(
                  'registration.organizationDetails.legalRepresentativeNamePlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.legal_representative_name && (
              <FormMessage>
                {form.formState.errors.legal_representative_name.type === 'required'
                  ? t(
                      'registration.organizationDetails.legalRepresentativeNameValidation',
                    )
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.fiscalNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('fiscal_number', {
                  required: true,
                  pattern: {
                    value:
                      /^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$/,
                    message: t('registration.memberDetails.fiscalNumberValidation'),
                  },
                })}
                {...field}
                placeholder={t(
                  'registration.organizationDetails.fiscalNumberPlaceholder',
                )}
              />
            </FormControl>
            {form.formState.errors.fiscal_number && (
              <FormMessage>
                {form.formState.errors.fiscal_number
                  ? t('registration.memberDetails.fiscalNumberValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        control={form.control}
        name="birth_date"
        render={({ field }) => (
          <FormItem className="mb-6 flex flex-col">
            <Label>{t('registration.organizationDetails.birthDate')}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'justify-start text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP', { locale: it }) // TODO: use i18n
                  ) : (
                    <span>{t('misc.selectDate')}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className=" w-auto p-0">
                <Calendar
                  locale={it}
                  mode="single"
                  captionLayout="dropdown-buttons"
                  selected={new Date(field.value ?? '')}
                  onSelect={(date) => {
                    if (!date) return;
                    const utcDate = new Date(
                      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                    );
                    form.setValue('birth_date', utcDate.toISOString());
                  }}
                  fromYear={1900}
                  toYear={2005}
                  {...form.register('birth_date', { required: true })}
                  required
                />
              </PopoverContent>
            </Popover>
            {form.formState.errors.birth_date && (
              <FormMessage>
                {form.formState.errors.birth_date.type === 'required' &&
                !form.formState.errors.birth_date.ref?.value
                  ? t('registration.memberDetails.birthDateValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />

      <FormField
        name="address"
        // control={form.control}
        render={() => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.birthPlace')}</Label>{' '}
            <FormControl>
              <AddressAutocomplete
                placeholder={t('registration.organizationDetails.birthPlacePlaceholder')}
                types={['(cities)']}
                onAddressChange={(address) => {
                  form.setValue('birth_place_country', address.address_country);
                  form.setValue('birth_place_region', address.address_region);
                  form.setValue('birth_place_city', address.address_city);
                  form.setValue('birth_place_municipality', address.address_municipality);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <Controller
        name="birth_place_country"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.birthPlaceCountry')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_country', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_country && (
              <FormMessage>
                {form.formState.errors.birth_place_country.type === 'required'
                  ? t('registration.memberDetails.birthPlaceCountryValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_region"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.birthPlaceRegion')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_region', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_region && (
              <FormMessage>
                {form.formState.errors.birth_place_region.type === 'required'
                  ? t('registration.memberDetails.birthPlaceRegionValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_city"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.birthPlaceCity')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_city', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_city && (
              <FormMessage>
                {form.formState.errors.birth_place_city.type === 'required'
                  ? t('registration.memberDetails.birthPlaceCityValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_municipality"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.organizationDetails.birthPlaceMunicipality')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_municipality', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_municipality && (
              <FormMessage>
                {form.formState.errors.birth_place_municipality.type === 'required'
                  ? t('registration.memberDetails.birthPlaceMunicipalityValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
    </>
  );
};

const LegalPersonFields = ({ form }: { form: ReturnType<typeof useMemberForm> }) => {
  const { t } = useTranslation();
  console.log(form.formState.errors.birth_date);

  return (
    <>
      <Controller
        name="first_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.firstName')}</Label>
            <FormControl>
              <Input
                {...form.register('first_name', { required: true })}
                {...field}
                placeholder={t('registration.memberDetails.firstNamePlaceholder')}
              />
            </FormControl>
            {form.formState.errors.first_name && (
              <FormMessage>
                {form.formState.errors.first_name.type === 'required'
                  ? t('registration.memberDetails.firstNameValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="last_name"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.lastName')}</Label>
            <FormControl>
              <Input
                {...form.register('last_name', { required: true })}
                {...field}
                placeholder={t('registration.memberDetails.lastNamePlaceholder')}
              />
            </FormControl>
            {form.formState.errors.last_name && (
              <FormMessage>
                {form.formState.errors.last_name.type === 'required'
                  ? t('registration.memberDetails.lastNameValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="phone_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.phoneNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('phone_number', {
                  required: true,
                  pattern: {
                    value: /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,8}$/,
                    message: t('registration.memberDetails.phoneNumberValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.memberDetails.phoneNumberPlaceholder')}
              />
            </FormControl>
            {form.formState.errors.phone_number && (
              <FormMessage>
                {form.formState.errors.phone_number
                  ? t('registration.memberDetails.phoneNumberValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="fiscal_number"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.fiscalNumber')}</Label>
            <FormControl>
              <Input
                {...form.register('fiscal_number', {
                  required: true,
                  pattern: {
                    value:
                      /^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$/,
                    message: t('registration.memberDetails.fiscalNumberValidation'),
                  },
                })}
                {...field}
                placeholder={t('registration.memberDetails.fiscalNumberPlaceholder')}
              />
            </FormControl>
            {form.formState.errors.fiscal_number && (
              <FormMessage>
                {form.formState.errors.fiscal_number
                  ? t('registration.memberDetails.fiscalNumberValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        control={form.control}
        name="birth_date"
        render={({ field }) => (
          <FormItem className="mb-6 flex flex-col">
            <Label>{t('registration.memberDetails.birthDate')}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'justify-start text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {field.value ? (
                    format(field.value, 'PPP', { locale: it }) // TODO: use i18n
                  ) : (
                    <span>{t('misc.selectDate')}</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className=" w-auto p-0">
                <Calendar
                  locale={it}
                  mode="single"
                  captionLayout="dropdown-buttons"
                  selected={new Date(field.value ?? '')}
                  onSelect={(date) => {
                    if (!date) return;
                    const utcDate = new Date(
                      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                    );
                    form.setValue('birth_date', utcDate.toISOString());
                  }}
                  fromYear={1900}
                  toYear={2005}
                  {...form.register('birth_date', { required: true })}
                  required
                />
              </PopoverContent>
            </Popover>
            {form.formState.errors.birth_date && (
              <FormMessage>
                {form.formState.errors.birth_date.type === 'required' &&
                !form.formState.errors.birth_date.ref?.value
                  ? t('registration.memberDetails.birthDateValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />

      <FormField
        name="address"
        // control={form.control}
        render={() => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlace')}</Label>{' '}
            <FormControl>
              <AddressAutocomplete
                placeholder={t('registration.memberDetails.birthPlacePlaceholder')}
                types={['(cities)']}
                // defaultValue={field.value}
                onAddressChange={(address) => {
                  form.setValue('birth_place_country', address.address_country);
                  form.setValue('birth_place_region', address.address_region);
                  form.setValue('birth_place_city', address.address_city);
                  form.setValue('birth_place_municipality', address.address_municipality);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <Controller
        name="birth_place_country"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceCountry')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_country', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_country && (
              <FormMessage>
                {form.formState.errors.birth_place_country.type === 'required'
                  ? t('registration.memberDetails.birthPlaceCountryValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_region"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceRegion')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_region', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_region && (
              <FormMessage>
                {form.formState.errors.birth_place_region.type === 'required'
                  ? t('registration.memberDetails.birthPlaceRegionValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_city"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceCity')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_city', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_city && (
              <FormMessage>
                {form.formState.errors.birth_place_city.type === 'required'
                  ? t('registration.memberDetails.birthPlaceCityValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
      <Controller
        name="birth_place_municipality"
        control={form.control}
        render={({ field }) => (
          <FormItem className="mb-6">
            <Label>{t('registration.memberDetails.birthPlaceMunicipality')}</Label>
            <FormControl>
              <Input
                {...form.register('birth_place_municipality', { required: true })}
                {...field}
              />
            </FormControl>
            {form.formState.errors.birth_place_municipality && (
              <FormMessage>
                {form.formState.errors.birth_place_municipality.type === 'required'
                  ? t('registration.memberDetails.birthPlaceMunicipalityValidation')
                  : null}
              </FormMessage>
            )}
          </FormItem>
        )}
      />
    </>
  );
};
