import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';
import { AddressAutocomplete } from '../ui/custom/addressAutocomplete';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Loader } from '../ui/loader';
import { useMemberForm } from './hooks/useMemberForm';
import { MemberFormParams } from './types/memberForm';

export const LocationInformationForm = ({
  member,
  title,
  submitText,
  cancel,
  onSuccess,
}: MemberFormParams) => {
  const { t } = useTranslation();
  const { onSubmit, isPending, ...form } = useMemberForm(member, onSuccess);

  return (
    <div className="flex flex-col gap-8">
      {title}
      <Form {...form}>
        <form className="max-w-[500px]" onSubmit={onSubmit}>
          <FormField
            name="address"
            render={() => (
              <FormItem className="mb-6">
                <Label>{t('registration.locationInformation.title')}</Label>
                <FormControl>
                  <AddressAutocomplete
                    onAddressChange={(address) => {
                      Object.entries(address).forEach(([key, value]) => {
                        if (value && key in member) {
                          form.setValue(
                            key as Parameters<typeof form.setValue>[0],
                            value,
                          );
                        }
                      });

                      // Trigger validation
                      form.trigger([
                        'address_country',
                        'address_region',
                        'address_city',
                        'address_municipality',
                        'address_zip_code',
                        'address_route',
                        'address_street_number',
                      ]);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Controller
            name="address_country"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressCountry.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_country', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressCountry.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_country && (
                  <FormMessage>
                    {form.formState.errors.address_country.type === 'required'
                      ? t(
                          'registration.locationInformation.addressCountry.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_region */}
          <Controller
            name="address_region"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressRegion.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_region', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressRegion.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_region && (
                  <FormMessage>
                    {form.formState.errors.address_region.type === 'required'
                      ? t(
                          'registration.locationInformation.addressRegion.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_city */}
          <Controller
            name="address_city"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressCity.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_city', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressCity.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_city && (
                  <FormMessage>
                    {form.formState.errors.address_city.type === 'required'
                      ? t('registration.locationInformation.addressCity.fieldValidation')
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_municipality */}
          <Controller
            name="address_municipality"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressMunicipality.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_municipality', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressMunicipality.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_municipality && (
                  <FormMessage>
                    {form.formState.errors.address_municipality.type === 'required'
                      ? t(
                          'registration.locationInformation.addressMunicipality.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_zip_code */}
          <Controller
            name="address_zip_code"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressZipCode.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_zip_code', {
                      required: true,
                      pattern: {
                        value: /^\d{5}$/,
                        message: t(
                          'registration.locationInformation.addressZipCode.fieldValidation',
                        ),
                      },
                    })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressZipCode.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_zip_code && (
                  <FormMessage>
                    {form.formState.errors.address_zip_code
                      ? t(
                          'registration.locationInformation.addressZipCode.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_route */}
          <Controller
            name="address_route"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressStreetName.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_route', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressStreetName.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_route && (
                  <FormMessage>
                    {form.formState.errors.address_route.type === 'required'
                      ? t(
                          'registration.locationInformation.addressStreetName.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* address_street_number */}
          <Controller
            name="address_street_number"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.addressStreetNumber.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('address_street_number', { required: true })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.addressStreetNumber.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.address_street_number && (
                  <FormMessage>
                    {form.formState.errors.address_street_number.type === 'required'
                      ? t(
                          'registration.locationInformation.addressStreetNumber.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
              </FormItem>
            )}
          />
          {/* cadastral_section */}
          <div className="w-full h-[1px] bg-slate-300 mb-2 mt-10" />
          <Label className="text-slate-700">
            I dati catastali di un immobile sono il codice che permette di identificarlo.
            Puoi trovare i dati catastali nelle prime pagine del tuo atto di proprietà o
            nel tuo contratto di affitto. In presenza di più codici catastali, indicare
            solo quello relativo alla sezione dell'immobile principale.
          </Label>
          <Controller
            name="cadastral_section"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6 mt-6">
                <Label>
                  {t('registration.locationInformation.cadastralSection.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('cadastral_section', {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z0-9]{1,3}$/,
                        message: t(
                          'registration.locationInformation.cadastralSection.fieldValidation',
                        ),
                      },
                    })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.cadastralSection.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.cadastral_section && (
                  <FormMessage>
                    {form.formState.errors.cadastral_section
                      ? t(
                          'registration.locationInformation.cadastralSection.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralSection.fieldDescription',
                  )}
                </FormDescription>
              </FormItem>
            )}
          />
          {/* cadastral_paper */}
          <Controller
            name="cadastral_paper"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralPaper.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('cadastral_paper', {
                      required: true,
                      pattern: {
                        value: /^\d+$/,
                        message: t(
                          'registration.locationInformation.cadastralPaper.fieldValidation',
                        ),
                      },
                    })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.cadastralPaper.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.cadastral_paper && (
                  <FormMessage>
                    {form.formState.errors.cadastral_paper
                      ? t(
                          'registration.locationInformation.cadastralPaper.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
                <FormDescription>
                  {t('registration.locationInformation.cadastralPaper.fieldDescription')}
                </FormDescription>
              </FormItem>
            )}
          />
          {/* cadastral_particle */}
          <Controller
            name="cadastral_particle"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralParticle.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('cadastral_particle', {
                      required: true,
                      pattern: {
                        value: /^\d+$/,
                        message: t(
                          'registration.locationInformation.cadastralParticle.fieldValidation',
                        ),
                      },
                    })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.cadastralParticle.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.cadastral_particle && (
                  <FormMessage>
                    {form.formState.errors.cadastral_particle
                      ? t(
                          'registration.locationInformation.cadastralParticle.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralParticle.fieldDescription',
                  )}
                </FormDescription>
              </FormItem>
            )}
          />
          {/* cadastral_subsection */}
          <Controller
            name="cadastral_subsection"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label>
                  {t('registration.locationInformation.cadastralSubsection.fieldName')}
                </Label>
                <FormControl>
                  <Input
                    {...form.register('cadastral_subsection', {
                      required: true,
                      pattern: {
                        value: /^\d+$/,
                        message: t(
                          'registration.locationInformation.cadastralSubsection.fieldValidation',
                        ),
                      },
                    })}
                    {...field}
                    placeholder={t(
                      'registration.locationInformation.cadastralSubsection.fieldPlaceholder',
                    )}
                  />
                </FormControl>
                {form.formState.errors.cadastral_subsection && (
                  <FormMessage>
                    {form.formState.errors.cadastral_subsection
                      ? t(
                          'registration.locationInformation.cadastralSubsection.fieldValidation',
                        )
                      : null}
                  </FormMessage>
                )}
                <FormDescription>
                  {t(
                    'registration.locationInformation.cadastralSubsection.fieldDescription',
                  )}
                </FormDescription>
              </FormItem>
            )}
          />
          <div className="mt-8 flex flex-col sm:flex-row gap-2 sm:gap-0">
            {cancel && (
              <Button
                type="button"
                variant="secondary"
                className="mr-4"
                onClick={cancel.onClick}
              >
                {cancel.text}
              </Button>
            )}
            <Button
              type="submit"
              className={cn({ 'cursor-progress': isPending })}
              disabled={isPending}
            >
              {isPending && <Loader className="mr-1" />}
              {submitText}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
