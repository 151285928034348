import { CheckCircle2Icon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import { useUserCommunity } from '../community/hooks/useUserCommunity';
import {
  isMemberReadyForContractSigning,
  useIsMemberFinishedRegistration,
} from '../member/hooks/useIsMemberFinishedRegistration';

type Props = {
  mode?: 'registration' | 'profile';
};

const kebabToCamel = (str: string): string =>
  str
    .split('-')
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join('');

export const RegistrationNavigationTabs = ({ mode = 'registration' }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathWithoutQuery = location.pathname.split('?')[0];
  const segments = pathWithoutQuery.split('/');
  const currentTab = segments.pop() || '';
  const { member, isLoading } = useUserCommunity();
  const activeTabRef = useRef<HTMLButtonElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {
    isMemberTypeStepCompleted,
    isMemberRoleStepCompleted,
    isMemberDetailsStepCompleted,
    isEnergyInformationStepCompleted,
    isLocationInformationStepCompleted,
    isFinancialInformationStepCompleted,
  } = useIsMemberFinishedRegistration();

  const pathToTabTitle = (path: string): string => {
    if (path === 'energy-information') {
      return member?.member_role === 'CONSUMER'
        ? t('registration.tabs.energyInformationConsumer')
        : t('registration.tabs.energyInformationProducer');
    } else if (path == 'member-details') {
      return member?.type === 'PRIVATE_COMPANY'
        ? t('registration.tabs.memberDetailsOrganization')
        : t('registration.tabs.memberDetailsPersonal');
    }
    return t(`registration.tabs.${kebabToCamel(path)}`);
  };

  const tabCompletionStatus = [
    isMemberTypeStepCompleted,
    isMemberRoleStepCompleted,
    isMemberDetailsStepCompleted,
    isEnergyInformationStepCompleted,
    isLocationInformationStepCompleted,
    isFinancialInformationStepCompleted,
  ];

  const tabPaths = [
    'select-member-type',
    'choose-your-role',
    'member-details',
    'energy-information',
    'location-information',
    'financial-information',
  ];

  useEffect(() => {
    if (activeTabRef.current && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const activeTab = activeTabRef.current;

      const scrollLeft =
        activeTab.offsetLeft - container.offsetWidth / 2 + activeTab.offsetWidth / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [currentTab]);

  if (isLoading || !member) {
    return null;
  }

  return (
    <div ref={scrollContainerRef} className="w-full overflow-x-auto pb-2">
      <Tabs
        className="w-[400px] min-w-max"
        defaultValue="select-member-type"
        value={currentTab}
        onValueChange={(value) => navigate(value)}
      >
        <TabsList className="w-full">
          {tabPaths.map((path, index) => {
            const isDisabled =
              index > 0 && tabCompletionStatus.slice(0, index).some((value) => !value);

            if (member?.type === 'PRIVATE_COMPANY' && path === 'financial-information') {
              return;
            }

            return (
              <TabsTrigger
                key={path}
                ref={currentTab === path ? activeTabRef : undefined}
                onClick={() => !isDisabled && navigate(path)}
                value={path}
                className={'flex items-center gap-2 whitespace-nowrap'}
                disabled={isDisabled}
              >
                {mode === 'registration' &&
                  tabCompletionStatus.slice(0, index + 1).every(Boolean) && (
                    <CheckCircle2Icon className="text-success" size="16" />
                  )}
                {pathToTabTitle(path)}
              </TabsTrigger>
            );
          })}
          {mode === 'registration' && (
            <TabsTrigger
              ref={currentTab === 'review-sign-contract' ? activeTabRef : undefined}
              onClick={() => navigate('review-sign-contract')}
              value="review-sign-contract"
              className="whitespace-nowrap"
              disabled={!isMemberReadyForContractSigning(member)}
            >
              {t('registration.tabs.reviewAndSignContract')}
            </TabsTrigger>
          )}
        </TabsList>
      </Tabs>
    </div>
  );
};
